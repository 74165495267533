import './App.css';
import Home from './Pages/Home'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Links from "./Links.json"
import EquipmentList from './Pages/EquipmentList';
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
        {Links.map((link) => {
          return (
            <Route
              key={link.extension}
              path={link.extension}
              exact
              component={() => {
                window.location.href = link.url;
              }}
            />
          );
        })}
        <Route path="/" component={Home} />
        <Route path="/equipmentlist" component={EquipmentList} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
