import './Info.css';
import { useState } from 'react';
const DevInfo = () => {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<>
			<div className='info-wrapper'>
				<h3 className='info-header'>
					swe at{' '}
					{
						<a
							className='header-link'
							href={'https://carleton.ca/'}
							target='_blank'
							rel='noreferrer'
						>
							carleton university
						</a>
					}
					{'.'}
				</h3>
			</div>
			<div className='info-wrapper' onMouseLeave={handleMouseLeave}>
				<h3 className='info-header'>
					{'prev. '}
					<div class='tooltip' onMouseEnter={handleMouseEnter}>
						{isHovered ? ' machine learning software engineer' : ' mlse'}
					</div>{' '}
					intern at{' '}
					{
						<a
							className='header-link'
							href={'https://www.borealisai.com/'}
							target='_blank'
							rel='noreferrer'
						>
							{''}
							borealis ai
						</a>
					}
					.
				</h3>
			</div>
			<div className='info-wrapper'>
				<h3 className='info-header'>
					i'm also looking for 2024 new grad opportunities.
				</h3>
			</div>
		</>
	);
};

export default DevInfo;
