import "./HomeNavBar.css";
const HomeNavBar = (props) => {

  return (
    <ul className="home-nav-bar">
      <li
        className={"home-content-list-item" + (props.selected === 'dev' ? ' selected' : '')}
        onClick={() => {
          props.handleChange("dev");
        }}
      >
        dev
      </li>
      <li
        className={"home-content-list-item" + (props.selected === 'music' ? ' selected' : '')}
        onClick={() => {
          props.handleChange("music");
        }}
      >
        music
      </li>
    </ul>
  );
};

export default HomeNavBar;
