import "./EquipmentList.css";

const EquipmentList = (props) => {
  return (
    <div className="modal display-block">
      <section className="modal-main">
        <button className="button-60" type="button" onClick={props.handleClose}>
          X
        </button>
        <section>
          <section className="list-section">
            <div>
              <h2>my equipment</h2>
              <ul class="check-list">
                <li>fl studio 20</li>
                <li>akai mpk mini</li>
                <li>sennheiser hd 450bt</li>
              </ul>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default EquipmentList;
