import "./Header.css";
const ExternalsNavbar = (props) => {

  return (
    <header className= {props.className || "nav-bar"}>
      <ul className="header">
        {props.options.map((option) => {
          return (
            <li key={option.name} className="header-list-item">
              <a href={option.link || null} target="_blank" rel="noreferrer" onClick={option.onClick}>{option.name}</a>
            </li>
          );
        })}
      </ul>
    </header>
  );
};

export default ExternalsNavbar;
