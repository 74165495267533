const MusicInfo = () => {
	return (
		<>
			<div className='vmin-wrapper'>
				<div className='info-wrapper'>
					<h3 className='info-header'>
						amateur music producer and enthusiast.
					</h3>
				</div>
			</div>
			<div className='info-wrapper'>
				<h3 className='info-header'>feel free to check out my stuff.</h3>
			</div>
		</>
	);
};

export default MusicInfo;
