import { useEffect, useState } from 'react';
import DevInfo from '../Components/DevInfo';
import ExternalsNavbar from '../Components/ExternalsNavbar';
import HomeNavBar from '../Components/HomeNavBar';
import MusicInfo from '../Components/MusicInfo';
import './Home.css';

const Home = () => {
	const devOptions = [
		{ name: 'resume', link: '/resume' },
		{ name: 'github', link: 'https://github.com/guymorgenshtern' },
		{ name: 'linkedin', link: 'https://linkedin.com/in/guymorgenshtern' },
	];
	const musicOptions = [
		{ name: 'soundcloud', link: 'https://soundcloud.com/guyprod' },
		{ name: 'traktrain', link: 'https://traktrain.com/guyproduction#312904' },
	];

	const [homeContent, setHomeContent] = useState('dev');
	const handleHomePageContentChange = (content) => {
		setHomeContent(content);
	};

	useEffect(() => {
		handleHomePageContentChange(homeContent);
	}, [homeContent]);

	return (
		<div className='wrapper'>
			<div className='home-page'>
				<ExternalsNavbar
					options={homeContent === 'dev' ? devOptions : musicOptions}
				></ExternalsNavbar>
				<div className='inline-wrapper'>
					<HomeNavBar
						handleChange={handleHomePageContentChange}
						selected={homeContent}
					></HomeNavBar>
					<div className='content-wrapper'>
						<div>
							<h1> Hi, I'm Guy Morgenshtern</h1>
							{homeContent === 'dev' ? <DevInfo /> : <MusicInfo />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
